import store from '@/store'
const roles = new Set()
const user = store.getters.user
let UserRole
if (user !== null) {
  user.roles.forEach((role) => {
    return roles.add(role.name)
  })
  let itr = roles.values()
  UserRole = itr.next().value
}
export default UserRole
