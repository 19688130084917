<template>
  <div>
    <PageHeader />
    <router-view />
    <PageFooter />
  </div>
</template>

<script>
import PageHeader from '@/components/page/PageHeader.vue'
import PageFooter from '@/components/page/PageFooter.vue'

export default {
  name: 'AdminLayout',
  components: {
    PageHeader,
    PageFooter,
  },
}
</script>

<style lang="scss" scoped></style>
