<template>
  <div>
    <CFooter>
      <CContainer lg>
        <CRow>
          <CCol>
            <h4>Information</h4>
            <ul class="list-unstyled">
              <li v-for="(menu, i) in menus" :key="i">
                <router-link :to="'/page/' + menu.slug">{{
                  menu.title
                }}</router-link>
              </li>
            </ul>
          </CCol>
          <CCol>
            <h4>Services</h4>
            <ul class="list-unstyled">
              <li><router-link to="/page/contact">Contact Us</router-link></li>
            </ul>
          </CCol>
          <CCol>
            <h4>Account</h4>
            <ul class="list-unstyled">
              <li v-if="authUser === false">
                <router-link to="/pages/login">Login</router-link>
              </li>
              <li v-if="authUser === true">
                <a role="button" @click="logout">Logout</a>
              </li>
            </ul>
          </CCol>
        </CRow>
        <CRow>
          <CCol class="copyright"
            ><p>&copy; {{ year }} TOUCHPOINT All rights reserved.</p></CCol
          >
        </CRow>
      </CContainer>
    </CFooter>
  </div>
</template>

<script>
import axios from '@/axios'
import store from '@/store'
export default {
  name: 'PageFooter',
  data() {
    return {
      menus: '',
      year: '',
      authUser: false,
    }
  },
  beforeMount() {
    if (store.getters.user !== null) {
      this.authUser = true
    } else {
      this.authUser = false
    }
    this.get()
  },
  created() {
    // this.get()
    console.log('NODE_ENV', process.env.NODE_ENV)
    console.log('VUE_APP_BASE_URL', process.env.VUE_APP_BASE_URL)
    const date = new Date()
    this.year = date.getFullYear()
  },
  methods: {
    async get() {
      let url = '/pages/footer/getMenus'
      await axios
        .get(url)
        .then((res) => {
          if (res.data.status === 200) {
            this.menus = res.data.data
            console.log('menus', this.menus)
          }
        })
        .catch((error) => {
          console.log('error', error)
        })
    },
    logout() {
      // this.$toasted.success("Logged out Successfull");
      this.$toast.add({
        severity: 'success',
        summary: 'Success!',
        detail: 'You have successfully logged out!',
        life: 6000,
      })

      /**
       * Clear PWA Cache on logout
       */
      caches.keys().then((cacheNames) => {
        cacheNames.forEach((cacheName) => {
          caches.delete(cacheName)
        })
      })
      /**
       * Unregistrer ServiceWorker on logout
       */
      if (window.navigator && navigator.serviceWorker) {
        navigator.serviceWorker
          .getRegistrations()
          .then(function (registrations) {
            for (let registration of registrations) {
              registration.unregister()
            }
          })
      }
      this.$store.dispatch('user', null).then(() => {
        localStorage.removeItem('token')
        localStorage.setItem('vuex', null)
        this.$router.push('/home')
        this.authUser = false
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.footer {
  background: #303c54;
  color: #fff;
  padding: 2rem 0 0.5rem;
  h4 {
    text-align: left;
  }
  li {
    margin: 10px 0;

    a {
      color: #fff;
      transition: all 1s ease-in-out;
      font-size: 14px;
      &:hover {
        color: #007fb9;
        transition: all 1s ease-in-out;
      }
    }
  }
  .copyright {
    border-top: 1px solid #1f2737;
    color: #a0a6b3;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 1.5rem;
  }
}
</style>
