import { h, resolveComponent } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'
import store from '@/store'

import AdminLayout from '@/layouts/AdminLayout'
import DefaultLayout from '@/layouts/DefaultLayout'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: DefaultLayout,
    redirect: '/home',
    meta: {
      requiresAuth: false,
      title: 'Home',
    },
    children: [
      {
        path: '/home',
        name: 'Home',
        component: () => import('@/views/pages/HomePage'),
        meta: {
          title: 'Home',
        },
        children: [
          {
            path: '/home',
            name: 'Home',
            component: () => import('@/views/pages/HomePage'),
            meta: {},
          },
        ],
      },
    ],
  },
  {
    path: '/admin',
    name: 'Dashboard',
    component: AdminLayout,
    //redirect: '/admin',
    meta: {
      requiresAuth: true,
      title: 'Dashboard',
    },
    children: [
      {
        path: '/admin',
        name: 'Dashboard',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
        meta: {
          title: 'Dashboard',
        },
      },
      {
        path: '/outlets',
        name: 'Outlets',
        component: () => import('@/views/outlets'),
        meta: {
          title: 'Outlets',
        },
      },
      {
        path: '/outlet/create',
        name: 'Create Outlet',
        component: () => import('@/views/outlets/OutletForm.vue'),
        meta: {
          title: 'Create Outlet',
        },
      },
      {
        path: '/outlet/edit/:outletId',
        name: 'Edit Outlet',
        component: () => import('@/views/outlets/OutletForm.vue'),
        meta: {
          title: 'Edit Outlet',
        },
      },
      {
        path: '/outlet/:outletId',
        name: 'View Outlet',
        component: () => import('@/views/outlets/ViewOutlet.vue'),
        meta: {
          title: 'View Outlet',
        },
      },
      {
        path: '/outlet/settings',
        name: 'Outlet Settings',
        component: () => import('@/views/outlets/settings/index.vue'),
        meta: {
          title: 'Outlet Settings',
        },
      },
      /* {
                                                                                                                                                                                  path: '/outlet',
                                                                                                                                                                                  name: 'Outlet',
                                                                                                                                                                                  component: {
                                                                                                                                                                                    render() {
                                                                                                                                                                                      return h(resolveComponent('router-view'))
                                                                                                                                                                                    },
                                                                                                                                                                                  },
                                                                                                                                                                                  redirect: '/outlet/items',
                                                                                                                                                                                  children: [
                                                                                                                                                                                    {
                                                                                                                                                                                      path: '/items',
                                                                                                                                                                                      name: 'Items',
                                                                                                                                                                                      component: () => import('@/views/outlets/items'),
                                                                                                                                                                                    },                                                                                                   ],
                                                                                                                                                                                }, */
      {
        path: '/outlet/items',
        name: 'Items',
        component: () => import('@/views/outlets/items'),
        meta: {
          title: 'Outlet Items',
        },
      },
      {
        path: '/outlet/item/update/:outlet/:code',
        name: 'Update Items',
        component: () => import('@/views/outlets/items/UpdateForm.vue'),
        meta: {
          title: 'Update Outlet Item',
        },
      },
      {
        path: '/outlet/item/group/update',
        name: 'Group Update',
        component: () => import('@/views/outlets/items/GroupUpdate.vue'),
        meta: {
          title: 'Outlet Items | Group Update',
        },
      },
      {
        path: '/outlet/code/generate',
        name: 'Outlet Code',
        component: () => import('@/views/outlets/OutletCode'),
        meta: {
          title: 'Outlet | Code Generator',
        },
      },
      {
        path: '/users',
        name: 'Users',
        component: () => import('@/views/users'),
        meta: {
          title: 'Users',
        },
      },
      {
        path: '/users/create',
        name: 'Create User',
        component: () => import('@/views/users/UserForm.vue'),
        meta: {
          title: 'Create User',
        },
      },
      {
        path: '/users/edit/:userId',
        name: 'Edit User',
        component: () => import('@/views/users/UserForm.vue'),
        meta: {
          title: 'Edit User',
        },
      },
      {
        path: '/users/profile',
        name: 'Edit Profile',
        component: () => import('@/views/users/ProfileForm.vue'),
        meta: {
          title: 'Edit Profile',
        },
      },
      {
        path: '/admin/pages',
        name: 'All Pages',
        component: () => import('@/views/admin/pages'),
        meta: {
          title: 'All Pages',
        },
      },
      {
        path: '/admin/page/create',
        name: 'Create Page',
        component: () => import('@/views/admin/pages/PageForm.vue'),
        meta: {
          title: 'Create New Page',
        },
      },
      {
        path: 'admin/page/contact',
        name: 'Create Contact',
        component: () => import('@/views/admin/pages/ContactPage.vue'),
        meta: {
          title: 'Contact Page',
        },
      },
      {
        path: '/admin/page/edit/:page_id',
        name: 'Edit Page',
        component: () => import('@/views/admin/pages/PageForm.vue'),
        meta: {
          title: 'Edit Page',
        },
      },
      {
        path: '/admin/page/contact',
        name: 'ContactPage',
        component: () => import('@/views/admin/pages/ContactPage.vue'),
        meta: {
          title: 'Contact Page',
        },
      },
      {
        path: '/admin/payments/',
        name: 'Payments History',
        component: () => import('@/views/admin/payments/index.vue'),
        meta: {
          title: 'Payments History',
        },
      },
      {
        path: '/admin/payment/recharge',
        name: 'Outlet Recharge',
        component: () => import('@/views/admin/payments/recharge/index.vue'),
        meta: {
          title: 'Outlet Recharge',
        },
      },
      {
        path: '/admin/payment/:id',
        name: 'Payment Details',
        component: () => import('@/views/admin/payments/history/index.vue'),
        meta: {
          title: 'Payment History',
        },
      },

      {
        path: '/admin/payment/signup',
        name: 'Signup',
        component: () => import('@/views/admin/payments/signup'),
        meta: {
          title: 'Signup',
        },
      },
      {
        path: '/admin/payment/settings',
        name: 'Payment Settings',
        component: () => import('@/views/admin/payments/setting'),
        meta: {
          title: 'Payment Settings',
        },
      },
      {
        path: '/admin/payment/setting/create',
        name: 'Create Payment Setting',
        component: () =>
          import('@/views/admin/payments/setting/SettingForm.vue'),
        meta: {
          title: 'Create Payment Method',
        },
      },
      {
        path: '/admin/payment/setting/edit/:id',
        name: 'Edit Payment Setting',
        component: () =>
          import('@/views/admin/payments/setting/SettingForm.vue'),
        meta: {
          title: 'Edit Payment Method',
        },
      },
      {
        path: '/icons',
        name: 'Icons',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/icons/coreui-icons',
        children: [
          {
            path: '/icons/coreui-icons',
            name: 'CoreUI Icons',
            component: () => import('@/views/icons/CoreUIIcons.vue'),
          },
          {
            path: '/icons/brands',
            name: 'Brands',
            component: () => import('@/views/icons/Brands.vue'),
          },
          {
            path: '/icons/flags',
            name: 'Flags',
            component: () => import('@/views/icons/Flags.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/pages',
    redirect: '/pages/404',
    name: 'Pages',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    children: [
      {
        path: '404',
        name: 'Page404',
        component: () => import('@/views/pages/Page404'),
      },
      {
        path: '500',
        name: 'Page500',
        component: () => import('@/views/pages/Page500'),
      },
      {
        path: 'login',
        name: 'Login',
        component: () => import('@/views/pages/Login'),
        meta: {
          requiresAuth: false,
          title: 'TP License | Login',
        },
      },
      {
        path: 'register',
        name: 'Register',
        component: () => import('@/views/pages/Register'),
      },
    ],
  },
  {
    path: '/page',
    redirect: '/',
  },
  {
    path: '/page/:slug',
    name: 'CMS-Page',
    component: () => import('@/views/pages/SinglePage.vue'),
    meta: {
      title: 'Loading...',
    },
  },
  {
    path: '/page/contact',
    name: 'Contact',
    component: () => import('@/views/pages/ContactPage'),
    meta: {
      title: 'Contact Us',
    },
  },
  {
    path: '/outlet/recharge',
    name: 'Recharge Page',
    component: () => import('@/views/outlet/Recharge'),
    meta: {
      title: 'Recharge Outlet',
    },
  },
  {
    path: '/outlet/recharge/success',
    name: 'Recharge Success',
    component: () => import('@/views/outlet/Recharge/Success'),
    meta: {
      title: 'Recharge Success',
    },
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0, behavior: 'smooth' }
  },
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (to.fullPath !== '/pages/login') {
      // axios
      // .post("/check/auth/token")
      // .then((res) => {
      //     console.log("Auth res", res);
      // })
      // .catch((error) => {
      //     console.log("Auth Error", error);
      //     console.log("Auth Error response", error.response);
      //     if (error && error.response.status === 401) {
      //         console.log("Authentication required!");
      //         setTimeout(() => {
      //             localStorage.removeItem("token");
      //             localStorage.removeItem("roles");
      //             localStorage.removeItem("permissions");
      //             // Clear PWA Cache on Logout
      //             caches.keys().then((cacheNames) => {
      //                 cacheNames.forEach((cacheName) => {
      //                     caches.delete(cacheName);
      //                 });
      //             });
      //             // Also Unregister ServiceWorker on Logout
      //             if (window.navigator && navigator.serviceWorker) {
      //                 navigator.serviceWorker
      //                     .getRegistrations()
      //                     .then(function (registrations) {
      //                         for (let registration of registrations) {
      //                             registration.unregister();
      //                         }
      //                     });
      //             }
      //             store.dispatch("user", null);
      //             Vue.swal.close();
      //             Vue.toasted
      //                 .show("Please Login to continue!")
      //                 .goAway(6000);
      //             setTimeout(() => {
      //                 router.push("/login");
      //             }, 500);
      //         }, 2500);
      //     }
      // });
    }

    if (store.getters.user) {
      document.title = `${to.meta.title}` || 'TP License'
      next()
      return
    }
    next('/pages/login')
    document.title = 'TP License | Login'
  } else {
    next()
    document.title = `${to.meta.title}` || 'TP License'
  }
})

export default router
