<template>
  <CDropdown color="secondary">
    <CDropdownToggle placement="bottom-end" class="py-0" :caret="false">
      <CAvatar :src="avatar" size="md" />
    </CDropdownToggle>
    <CDropdownMenu class="pt-0 pb-0">
      <CDropdownHeader component="h6" class="bg-light fw-semibold py-2">
        Account
      </CDropdownHeader>
      <CDropdownItem role="button" @click="goTo('/users/profile')">
        <CIcon icon="cil-apps-settings" /> Profile
      </CDropdownItem>
      <CDropdownItem> <CIcon icon="cil-settings" /> Settings </CDropdownItem>
      <CDropdownItem
        v-if="$role === 'user'"
        role="button"
        @click="goTo('/admin/payment/recharge')"
      >
        <CIcon icon="cil-dollar" /> Recharge
        <!-- <CBadge color="secondary" class="ms-auto">{{ itemsCount }}</CBadge> -->
      </CDropdownItem>
      <!-- <CDropdownItem>
        <CIcon icon="cil-file" /> Projects
        <CBadge color="primary" class="ms-auto">{{ itemsCount }}</CBadge>
      </CDropdownItem> -->
      <CDropdownDivider />
      <!-- <CDropdownItem>
        <CIcon icon="cil-shield-alt" /> Lock Account
      </CDropdownItem> -->
      <CDropdownItem role="button" @click="logout">
        <CIcon icon="cil-lock-locked" /> Logout
      </CDropdownItem>
      <CDropdownHeader
        component="h6"
        class="bg-light fw-semibold py-2"
        v-c-tooltip="{
          placement: 'bottom',
          content:
            'User Name: ' + user && user !== null
              ? user.first_name
              : '' + '<br />Role: ' + $role,
        }"
      >
        <CIcon icon="cil-user" />
        {{ user && user !== null ? user.first_name : '' }}
        <CIcon
          icon="cil-caret-right"
          size="sm"
          class="icon-custom fill-secondary"
        />
      </CDropdownHeader>
      <!-- <CDropdownHeader
        ><CIcon icon="cil-user" /> {{ user.name }}</CDropdownHeader
      > -->
    </CDropdownMenu>
  </CDropdown>
</template>

<script>
// import avatar from '@/assets/images/avatars/8.jpg'
import avatar from '@/assets/images/avatars/undraw_profile.svg'
import { mapGetters } from 'vuex'

export default {
  name: 'AppHeaderDropdownAccnt',
  setup() {
    return {
      avatar: avatar,
      itemsCount: 42,
    }
  },
  data() {
    return {
      role: null,
    }
  },
  computed: {
    ...mapGetters(['user']),
  },
  created() {
    // this.role.add(this.$role)
    // this.role
    this.role = this.$role
    console.log('userRole', this.$role)
  },
  methods: {
    logout() {
      // this.$toasted.success("Logged out Successfull");

      /**
       * Clear PWA Cache on logout
       */
      caches.keys().then((cacheNames) => {
        cacheNames.forEach((cacheName) => {
          caches.delete(cacheName)
        })
      })
      /**
       * Unregistrer ServiceWorker on logout
       */
      if (window.navigator && navigator.serviceWorker) {
        navigator.serviceWorker
          .getRegistrations()
          .then(function (registrations) {
            for (let registration of registrations) {
              registration.unregister()
            }
          })
      }
      this.$store.dispatch('user', null).then(() => {
        localStorage.removeItem('token')
        localStorage.setItem('vuex', null)
        this.$router.push('/pages/login')
      })
    },
    goTo(link) {
      this.$router.push(link)
    },
  },
}
</script>
<style scoped>
.btn-check:checked + .btn,
:not(.btn-check) + .btn:active,
.btn:first-child:active,
.btn.active,
.btn.show {
  border-color: transparent !important;
}
.dropdown-header {
  &:not(:first-child) {
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
  }
}
.icon-custom {
  width: 0.675rem !important;
  height: 0.675rem !important;
  font-size: 0.675rem !important;
}
.fill-secondary {
  fill: var(--ci-secondary-color) !important;
}
</style>
