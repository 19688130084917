<template>
  <div>
    <CNavbar color-scheme="light" class="bg-primary">
      <CContainer lg>
        <CNavbarBrand href="#">
          <img
            src="@/assets/images/icons/sygnet.svg"
            alt="TP License"
            height="32"
          />
        </CNavbarBrand>
        <CNavbarNav class="ml-auto">
          <CNavItem>
            <!-- <CNavLink href="#" class="text-white">{{ linkText }}</CNavLink> -->
            <router-link
              class="text-white text-decoration-none"
              :to="linkUrl"
              >{{ linkText }}</router-link
            >
          </CNavItem>
        </CNavbarNav>
        <!-- <span class="navbar-text text-white">
          Navbar text with an inline element
        </span> -->
      </CContainer>
    </CNavbar>
  </div>
</template>

<script>
import store from '@/store'
// import logo from '@/assets/images/icons/sygnet.svg'
export default {
  name: 'PageHeader',
  data() {
    return {
      logo: '',
      linkText: '',
      linkUrl: '',
    }
  },
  created() {
    this.links()
  },
  methods: {
    links() {
      if (store.getters.user !== null) {
        this.linkText = 'Dashboard'
        this.linkUrl = '/admin'
      } else {
        this.linkText = 'Login'
        this.linkUrl = '/pages/login'
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
