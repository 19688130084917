<template>
  <CFooter>
    <div>
      <span>{{ this.$appName }} {{ this.$appVersion }}</span>
      <span class="ms-3 ml-5"
        >&copy; {{ new Date().getFullYear() }} TOUCHPOINT.</span
      >
    </div>
    <div class="ms-auto">
      <span class="me-1" target="_blank">Powered by</span>
      <a href="https://techindu.com">TECHINDU Inc.</a>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'AppFooter',
}
</script>
<style lang="scss">
.footer {
  font-size: 12px !important;
  a {
    text-decoration: none;
    color: #007fb9;
  }
}
</style>
