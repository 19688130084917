<template>
  <router-view />
  <ConfirmDialog />
  <Toast />
</template>

<style lang="scss">
// Import Main styles for this application
@import 'styles/style';
</style>
