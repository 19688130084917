<template>
  <CSidebar
    position="fixed"
    :unfoldable="sidebarUnfoldable"
    :visible="sidebarVisible"
    @visible-change="
      (event) =>
        $store.commit({
          type: 'updateSidebarVisible',
          value: event,
        })
    "
  >
    <CSidebarBrand>
      <img
        :src="logoNegative"
        alt="Logo"
        height="35"
        class="sidebar-brand-full"
      />
      <img :src="sygnet" alt="Logo" height="35" class="sidebar-brand-narrow" />
    </CSidebarBrand>
    <AppSidebarNav />
    <CSidebarToggler
      class="d-none d-lg-flex"
      @click="$store.commit('toggleUnfoldable')"
    />
  </CSidebar>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { AppSidebarNav } from './AppSidebarNav'
// import { logoNegative } from '@/assets/brand/logo-negative'
// import { sygnet } from '@/assets/brand/sygnet'
import logoNegative from '@/assets/images/icons/logo-sidebar.svg'
import sygnet from '@/assets/images/icons/sygnet.svg'
export default {
  name: 'AppSidebar',
  components: {
    AppSidebarNav,
  },
  setup() {
    // console.log('Role', this.$roles)
    const store = useStore()
    return {
      logoNegative,
      sygnet,
      sidebarUnfoldable: computed(() => store.state.sidebarUnfoldable),
      sidebarVisible: computed(() => store.state.sidebarVisible),
    }
  },
}
</script>
