import axios from 'axios'

let headers = {
  'cache-control': 'no-cache',
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
  'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
  'X-Requested-With': 'XMLHttpRequest',
  // "Content-Type": "multipart/form-data", //enable it for file upload
}
let accessToken = localStorage.getItem('token')

if (accessToken && accessToken !== '') {
  headers.Authorization = `Bearer ${accessToken}`
}
// let base_url = ''
// if (process.env.NODE_ENV === 'production') {
//   base_url = process.env.VUE_APP_API_BASE_PROD
// } else {
//   // eslint-disable-next-line no-unused-vars
//   base_url = process.env.VUE_APP_BASE_URL_DEV
// }

export default axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,

  headers: headers,
})
