import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faAddressBook,
  faMobileScreen,
  faPhone,
  faUser,
  faFlag,
  faCheck,
  faBan,
  faTrashCan,
  faPenToSquare,
  faClockRotateLeft,
  faUserPlus,
  faRotateLeft,
  faUserPen,
  faIdCard,
  faBuilding,
  faEye,
  faFilter,
  faShop,
  faGear,
  faFileCirclePlus,
  faFloppyDisk,
  faSquarePen,
  faIndianRupeeSign,
  faCreditCard,
} from '@fortawesome/free-solid-svg-icons'

library.add(
  faAddressBook,
  faMobileScreen,
  faPhone,
  faUser,
  faFlag,
  faCheck,
  faBan,
  faTrashCan,
  faPenToSquare,
  faClockRotateLeft,
  faUserPlus,
  faRotateLeft,
  faUserPen,
  faIdCard,
  faBuilding,
  faEye,
  faFilter,
  faShop,
  faGear,
  faFileCirclePlus,
  faFloppyDisk,
  faSquarePen,
  faIndianRupeeSign,
  faCreditCard,
)

export default FontAwesomeIcon
