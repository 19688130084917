import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

export default createStore({
  state: {
    user: null,
    sidebarVisible: '',
    sidebarUnfoldable: false,
  },
  getters: {
    user: (state) => {
      return state.user
    },
  },
  mutations: {
    user: (state, user) => {
      state.user = user
    },
    toggleSidebar(state) {
      state.sidebarVisible = !state.sidebarVisible
    },
    toggleUnfoldable(state) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable
    },
    updateSidebarVisible(state, payload) {
      state.sidebarVisible = payload.value
    },
  },
  actions: {
    user: (context, user) => {
      context.commit('user', user)
    },
  },
  modules: {},
  plugins: [createPersistedState()],
})
