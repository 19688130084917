<template>
  <CHeader position="sticky" class="mb-2">
    <CContainer fluid>
      <CHeaderToggler class="ps-1" @click="$store.commit('toggleSidebar')">
        <CIcon icon="cil-menu" size="lg" />
      </CHeaderToggler>
      <CHeaderBrand class="mx-auto d-lg-none" to="/">
        <!-- <CIcon :icon="logo" height="48" alt="Logo" /> -->
        <img :src="brandLogo" alt="logo" height="48" />
      </CHeaderBrand>
      <CHeaderNav class="d-none d-md-flex me-auto">
        <AppBreadcrumb />
      </CHeaderNav>
      <CHeaderNav>
        <CNavItem>
          <CNavLink
            role="button"
            @click="goTo('/home')"
            v-c-tooltip="{ content: 'Go To Home', placement: 'bottom' }"
          >
            <CIcon class="mx-2" icon="cil-house" size="lg" />
          </CNavLink>
        </CNavItem>
        <!-- <CNavItem class="d-sm-none d-md-block">
          <CNavLink> User Name </CNavLink>
        </CNavItem> -->
        <AppHeaderDropdownAccnt />
      </CHeaderNav>
    </CContainer>
    <!-- <CHeaderDivider /> -->
  </CHeader>
</template>

<script>
import AppBreadcrumb from './AppBreadcrumb'
import AppHeaderDropdownAccnt from './AppHeaderDropdownAccnt'
// import { logo } from '@/assets/brand/logo'
import logo from '@/assets/images/icons/logo-header.svg'
export default {
  name: 'AppHeader',
  components: {
    AppBreadcrumb,
    AppHeaderDropdownAccnt,
  },
  data() {
    return {
      brandLogo: logo,
    }
  },
  /* setup() {
    return {
      logo,
    }
  }, */
  methods: {
    goTo(link) {
      setTimeout(() => {
        this.$router.push(link)
      }, 1000)
    },
  },
}
</script>
