import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import CoreuiVue from '@coreui/vue'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
import DocsExample from '@/components/DocsExample'

import FontAwesomeIcon from '@/assets/icons/FontAwesomeIcon'

import PrimeVue from 'primevue/config'
import ConfirmationService from 'primevue/confirmationservice'
import ConfirmDialog from 'primevue/confirmdialog'
import Toast from 'primevue/toast'
import ToastService from 'primevue/toastservice'
import Button from 'primevue/button'
import Dropdown from 'primevue/dropdown'
import Password from 'primevue/password'
import InputText from 'primevue/inputtext'
import Calendar from 'primevue/calendar'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import FileUpload from 'primevue/fileupload'
import InputSwitch from 'primevue/inputswitch'
import RadioButton from 'primevue/radiobutton'
import ProgressBar from 'primevue/progressbar'

import 'primevue/resources/themes/saga-blue/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'

import MyToast from 'vue-toastification'
// Import the CSS or use your own!
import 'vue-toastification/dist/index.css'

import 'vue3-easy-data-table/dist/style.css'

import '@/assets/scss/app.scss'

import packageInfo from '../package.json'
import UserRole from './components/UserRole'
const app = createApp(App)

app.config.globalProperties.APP_NAME = packageInfo.name.toUpperCase()
app.config.globalProperties.APP_VERSION = `v${packageInfo.version}`
app.config.globalProperties.$appName = packageInfo.name.toUpperCase()
app.config.globalProperties.$appVersion = `v${packageInfo.version}`
app.config.globalProperties.$dev = process.env.NODE_ENV === 'development'
app.config.globalProperties.$role = UserRole
app.config.globalProperties.$goTo = (param) => {
  return this.$router.push(param)
}
// console.log('Roles Main:', this.$role)
// require('dotenv').config()

app.use(store)
app.use(router)
app.use(CoreuiVue)
app.provide('icons', icons)
app.component('CIcon', CIcon)
app.component('FAIcon', FontAwesomeIcon)
app.use(PrimeVue)
app.component('DocsExample', DocsExample)
app.component('ConfirmDialog', ConfirmDialog)
app.component('Toast', Toast)
app.component('Dropdown', Dropdown)
app.component('Password', Password)
app.component('InputText', InputText)
app.component('Calendar', Calendar)
app.component('PButton', Button)
app.component('TabView', TabView)
app.component('TabPanel', TabPanel)
app.component('FileUpload', FileUpload)
app.component('InputSwitch', InputSwitch)
app.component('RadioButton', RadioButton)
app.component('ProgressBar', ProgressBar)

app.use(ConfirmationService)
app.use(ToastService)
app.use(MyToast)
// app.component('EasyDataTable', Vue3EasyDataTable)
app.mount('#app')
