import UserRole from './components/UserRole'

const nav = [
  {
    component: 'CNavItem',
    name: 'Dashboard',
    to: '/admin',
    icon: 'cil-speedometer',
  },
]

/**
 * Check Role and load menu items in Dashbord
 * @returns var
 */
let outlet = {
  component: 'CNavItem',
  name: 'Outlet',
  icon: 'cil-bank',
  items: [
    {
      component: 'CNavItem',
      name: 'New Outlet Code',
      to: '/outlet/code/generate',
    },
    {
      component: 'CNavItem',
      name: 'Outlet License Details',
      to: '/outlets',
    },
  ],
}
let payment_component = {
  component: 'CNavTitle',
  name: 'Payments',
}

let payments = {
  component: 'CNavGroup',
  name: 'Payments',
  icon: 'cil-dollar',
  items: [
    {
      component: 'CNavItem',
      name: 'Recharge',
      to: '/admin/payment/recharge',
    },
    {
      component: 'CNavItem',
      name: 'Payments History',
      to: '/admin/payments',
    },
  ],
}
let signup = {
  component: 'CNavItem',
  name: 'Signup',
  to: '/admin/payment/signup',
}
let payment_setting = {
  component: 'CNavItem',
  name: 'Payment Settings',
  icon: 'cil-asterisk-circle',
  to: '/admin/payment/settings',
}

let outlet_settings = {
  component: 'CNavItem',
  name: 'Settings',
  to: '/outlet/settings',
  icon: 'cil-cog',
}

let users = {
  component: 'CNavItem',
  name: 'Users',
  to: '/users',
  icon: 'cil-people',
}

let items_component = {
  component: 'CNavTitle',
  name: 'Outlet Items',
}
let outlet_items = {
  component: 'CNavItem',
  name: 'Menu Price Changes',
  to: '/outlet/items',
  icon: 'cil-basket',
}

let extras = {
  component: 'CNavTitle',
  name: 'Extras',
}
let pages = {
  component: 'CNavGroup',
  name: 'Pages',
  to: '/admin/pages',
  icon: 'cil-star',
  items: [
    {
      component: 'CNavItem',
      name: 'All Pages',
      to: '/admin/pages',
    },
    {
      component: 'CNavItem',
      name: 'Contact Page',
      to: '/admin/page/contact',
    },
  ],
}
// console.log('Nav length', nav.length)

if (UserRole === 'superadmin' || UserRole === 'admin') {
  nav.splice(1, 0, outlet, users)
  payments.items.splice(0, 0, signup)
}
if (UserRole === 'superadmin') {
  payments.items.splice(4, 0, payment_setting)
}

nav.splice(3, 0, payment_component, payments)

if (UserRole === 'superadmin') {
  nav.splice(5, 0, outlet_settings)
}
if (UserRole === 'superadmin' || UserRole === 'admin') {
  nav.splice(6, 0, items_component, outlet_items)
} else {
  nav.splice(5, 0, items_component, outlet_items)
}
/* if (UserRole === 'user') {
  nav.splice(5, 0, items_component, outlet_items)
} */
if (UserRole === 'superadmin') {
  nav.splice(nav.length, 0, extras, pages)
}

export default nav
